import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import cam from "../images/cam.svg";

const CancelPage = () => (
  <Layout>
    <SEO
      keywords={[
        `CancelPage`,
        `punkrockvideos`,
        `punk`,
        `records`,
        `skateboards`
      ]}
      title="Cancel"
    />

    <section className="flex flex-col items-center md:flex-row">
      <div className="md:w-2/3 md:mr-8">
        <h1 className="font-bold text-x1">Thanks for stopping by.</h1>
        <p className="text-x2">
          Sorry you did not want to place an order. If you have any questions
          please contact me via my webform on the contact page.
        </p>
      </div>

      <figure className="w-2/3 md:w-1/3">
        <img alt="logo" src={cam} />
      </figure>
    </section>
  </Layout>
);

export default CancelPage;
